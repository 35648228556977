<template>
	<div class="company">
		<div class="title">
			<div class="title-logo">
				<img src="../assets/image/logo2.png" >
			</div>
			<div class="title-left">
				<span>
					<router-link to="./" class="tabItem" exact>首页</router-link>
				</span>
				<span v-for="(item,index) in industryInfos" :key="index"  class="tabItem"
				@click="hang(index,item.industryId)">
					{{item.industryName}}
				</span>
				<span>
					<router-link to="./pj-login" class="tabItem">企业服务</router-link>
				</span>
				<span>
					<router-link to="./pj-about" class="tabItem">关于我们</router-link>
				</span>
			</div>
		</div>
		<div class="content">
			<div class="content-main">
				<div class="content-title">
					<div class="content-title-left">
						<div class="content-title-left-company">{{companyObj.companyName}}</div>
						<div class="content-title-left-inf">{{companyObj.scale}}·{{companyObj.industry}}</div>

					</div>
					<div class="content-title-right">
						<img src="../assets/image/swiper1.png">
					</div>
				</div>
				<div class="company-content-state">
					<div class="company-content-state-left">
						<div class="company-content-treatment">
							<div class="treatment-content-time">
								<div class="treatment-time-item">
									<img src="../assets/img/time.png">
									<span>{{companyObj.commuterTime}}</span>
								</div>
								<div class="treatment-day-item">
									<img src="../assets/img/day.png">
									<span>{{companyObj.workDate}}</span>
								</div>
							</div>
							<div class="treatment-content-welfare">

								<div class="selfCheckBox" v-for="( item,index) in companyObj.benefits" :key="index">

									<div class="selfCkeckImgBox-item2"><img
											:src="checkBoxImg[item.type-1].icon">{{item.name}}
									</div>
								</div>

							</div>
						</div>
						<div class="company-content-jieshao">
							<div class="company-content-jieshao-title">
								公司介绍
							</div>
							<div class="company-content-jieshao-main">
								{{companyObj.introduce}}
							</div>
						</div>
					</div>
					<div class="line">

					</div>
					<div class="company-content-state-right">
						<div class="content-state-right-title">
							基本信息
						</div>
						<div class="content-state-right-main">
							注册时间： {{companyObj.estTime}} <br>
							公司规模：{{companyObj.scale}}<br>
							公司地址：{{companyObj.address}}
						</div>
					</div>
				</div>
				<div class="content-card">
					<div class="content-card-title">
						全部职位 <span v-if="companyObj.companyPosts!=undefined">{{companyObj.companyPosts.length}}</span>
					</div>
					<div class="content-main-item" v-for="(item,index) in companyObj.companyPosts" :key="index"
					@click="detail(item.postId)">
						<div class="content-main-item-left">
							<div class="item-informate">
								<span>{{item.postTitle}}</span>
								<div class="item-tag">
									<div class="item-tag-item">
										<img src="../assets/image/tag1.png">
										<span>{{item.eduRequire}}</span>
									</div>
									<div class="item-tag-item2">
										<img src="../assets/image/tag2.png">
										<span>{{item.expRequire}}</span>
									</div>
								</div>
								<div class="item-informate-di">
									<img src="../assets/image/di.png">
									<span>
										{{pcaaProvince(item.province)}}  {{pcaaCity(item.province,item.city)}}
									</span>
								</div>
							</div>
							<div class="item-xin">
								<span>{{price(item.salaryType)}} 元/月</span>
							</div>
						</div>
						<div class="line2">
							<div class="line-main">
					
							</div>
						</div>
						<div class="content-main-item-right">
							<div class="item-right-header">
								<img v-lazy="item.pubUser.headImg">
							</div>
							<div class="item-right-main">
								<div class="item-right-main-top">
									{{item.pubUser.nickName}}・<span>{{item.pubUser.position}}</span>
								</div>
								<div class="item-right-main-bottom">
									<span>{{item.pubUser.companyName}}</span>
									<span>{{item.pubUser.companyScale}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../components/common/Footer.vue'
	import {
		pcaa
	} from 'area-data';
	import $http from '../common/api/axios.js'
	import {
		detail,industryInfo
	} from '../common/api/api.js'
	export default {
		data() {
			return {
				industryInfos:[],
				companyObj: {},
				checkBoxImg: [{
						icon: require('../assets/img/welfare1.png')
					},
					{
						icon: require('../assets/img/welfare2.png')
					},
					{
						icon: require('../assets/img/welfare3.png')
					},
					{
						icon: require('../assets/img/welfare4.png')
					},
					{
						icon: require('../assets/img/welfare5.png')
					},
					{
						icon: require('../assets/img/welfare6.png')
					},
					{
						icon: require('../assets/img/welfare7.png')
					},
					{
						icon: require('../assets/img/welfare8.png')
					},
					{
						icon: require('../assets/img/welfare9.png')
					},
				]
			}
		},
		components: {
			Footer
		},
		methods: {
			hang(index, id) {
				this.$router.push({path:'/pj-information',query: {value:id,currcet:index}})
			},
			pcaaProvince(province) {
				let num1 = 86
				let num2 = province
				return pcaa[num1][num2]
			},
			pcaaCity(province, city) {
				let num1 = province
				let num2 = city
				return pcaa[num1][num2]

			},
			pcaaDistrict(city, district) {
				let num1 = city
				let num2 = district
				return pcaa[num1][num2]
			},
			price(salaryType) {
				let xinoptions = this.$store.state.xinoptions;
				return xinoptions[salaryType].label
			},
			detail(postId) {
				let id = postId
				this.$router.push({
					path: '/pj-details',
					query: {
						id: id
					}
				})
			}
		},
		created() {
			let id = this.$route.query.id
			let that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: industryInfo,
				}).then((res) => {
					that.industryInfos = res.data.industryInfos
				}).catch(() => {
					
				})
				$http.request({
					url: detail,
					data: {
						"companyId": id
					}
				}).then((res) => {
					that.companyObj = res.data
				}).catch(() => {

				})
			})
		}
	}
</script>

<style scoped>
	.title-left{
		margin-left: 1.45rem;
	}
	.title-left span {
		margin-left: .64rem;
	}
	.tabItem {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		text-decoration:none
	}
	.router-link-active {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color:#E41D1D;
		border-bottom: .04rem solid #E41D1D;
	}
	
	.title {
		display: flex;
		align-items: center;
		width: 100%;
		height: .72rem;
		z-index: 999;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #FFFFFF;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(172, 172, 172, 0.1);
		transform: translate3d(0, 0, 0);
	}
	.title-logo{
		margin-left: 3.6rem;
	}
	.title-logo img{
		width: 1.5rem;
	}
	.item-right-main-top {
		padding-top: .1rem;
		margin-bottom: .16rem;
		font-size: .16rem;
		font-weight: 600;
		color: #333333;
	}
	.item-right-main-top >span {
		font-size: .16rem;
		font-weight: 400;
		color: #666666!important;
	}
	.item-right-header img {
		width: .76rem;
		border-radius: .38rem;
	}
	.item-right-header{
		margin-right: .18rem;
	}
	.content-main-item-right {
		display: flex;
		padding-top: .18rem;
		padding-left: .6rem;
	}
	
	.line-main {
		width: 0px;
		height: 1.10rem;
		border-right: 1px dashed #707070;
		opacity: .5;
		
	}
	
	.line {
		opacity: .4;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: .1rem;
	}
	
	.item-xin span {
		font-size: .24rem;
		color: #EA1C1C;
		font-weight: 500;
		position: absolute;
		right: .2rem;
	}
	
	.item-xin {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}
	
	.item-informate-di span {
		font-size: .14rem;
		font-weight: 400;
		color: #666666;
	}
	
	.item-informate-di img {
		margin-right: .06rem;
		width: .13rem;
	}
	
	.item-informate-di {
		display: flex;
		align-items: center;
	}
	
	.item-informate>span {
		margin-bottom: .16rem;
		font-size: .22rem;
		font-weight: bold;
	}
	
	.item-informate {
		width: 7rem;
		display: flex;
		flex-direction: column;
	}
	
	.content-main-item-left {
		display: flex;
		width: 7rem;
	}
	
	.item-tag-item2 span {
		font-size: .12rem;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.item-tag-item2 img {
		margin-right: .05rem;
		width: .16rem;
	}
	
	.item-tag-item2 {
		padding-left: .10rem;
		padding-right:.10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: .64rem;
		height: .20rem;
		background: #54EDAC;
	}
	
	.item-tag-item span {
		font-size: .12rem;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.item-tag-item img {
		margin-right: .05rem;
		width: .16rem;
	}
	
	.item-tag-item {
		padding-left: .10rem;
		padding-right:.10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: .1rem;
		min-width: .64rem;
		height: .20rem;
		background: #5483ED;
	}
	
	.item-tag {
		display: flex;
		margin-bottom: .24rem;
	}
	
	.content-main-item {
		display: flex;
		align-items: center;
		padding-top: .15rem;
		padding-left: .46rem;
		box-sizing: border-box;
		width: 12rem;
		height: 1.85rem;
		border-top: 1px solid #E9E9E9;
		border-bottom: 1px solid #E9E9E9;
	}
	img[lazy="error"] {
		display: block;
		width: .64rem !important;
		height: .64rem !important;
		margin: 0 auto;
	}

	img[lazy="loading"] {
		display: block;
		width: .64rem !important;
		height: .64rem !important;
		margin: 0 auto;
	}
	.content {
		padding: 0 3.6rem;
		padding-bottom: .88rem;
		font-family: PingFang SC;
	}

	.content-main {
		width: 12rem;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
	}

	.content-title {
		width: 100%;
		height: 1.9rem;
		display: flex;
		align-items: center;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
	}

	.content-title-left {
		margin-left: .28rem;
	}

	.content-title-left-company {
		margin-bottom: .2rem;
		font-size: .3rem;
		font-weight: 400;
		color: #333333;
	}

	.content-title-left-inf {
		font-size: .14rem;
		font-weight: 300;
		color: #999999;
	}

	.content-title-right {
		margin-left: 5.9rem;
	}

	.content-title-right img {
		width: 2rem;
	}

	.company-content-state {
		display: flex;
		padding-top: .36rem;
		padding-left: .3rem;
	}

	.company-content-treatment {
		width: 8.1rem;
		margin-bottom: .3rem;
	}

	.treatment-content-time {
		display: flex;
		margin-bottom: .34rem;
	}

	.treatment-time-item {
		display: flex;
		align-items: center;
	}

	.treatment-time-item>img {
		margin-right: .1rem;
		width: .23rem;
	}

	.treatment-day-item {
		margin-left: 1.06rem;
		display: flex;
		align-items: center;
	}

	.treatment-day-item>img {
		margin-right: .1rem;
		width: .23rem;
	}

	.treatment-content-welfare {
		display: flex;
		flex-wrap: wrap;
	}

	.selfCheckBox {
		margin-bottom: .1rem;
	}

	.selfCkeckImgBox-item2 {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: .2rem;
		width: 1.2rem;
		height: .42rem;
		border: 1px solid #E9E9E9;

	}

	.selfCkeckImgBox-item2 img {
		width: .19rem;
		margin-right: .05rem;
	}

	.company-content-jieshao {}

	.company-content-jieshao-title {
		margin-bottom: .2rem;
		font-size: .2rem;
		font-weight: 600;
		color: #333333;
	}

	.company-content-jieshao-main {
		width: 6.66rem;
		font-size: .15rem;
		font-weight: 400;
		line-height: .3rem;
		color: #999999;
	}

	.line {
		width: 0px;
		height: 4.47rem;
		border-right: 1px dashed #C3C3C3;
		opacity: 1;
	}

	.company-content-state-right {
		padding-top: .4rem;
		padding-left: .35rem;
	}

	.content-state-right-title {
		margin-bottom: .25rem;
		font-size: .18rem;
		font-weight: 600;
		color: #333333;
	}

	.content-state-right-main {
		font-size: .15rem;
		font-weight: 400;
		line-height: .3rem;
		color: #666666;
	}
	.content-card-title{
		margin-left: .3rem;
		margin-bottom: .2rem;
		font-size: .20rem;
		font-weight: 600;
		color: #000000;
	}
	.content-card-title span{
		color: #EA1C1C;
	}
</style>
